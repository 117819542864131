.footerCategory {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}
.footerCategorySelect {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.footerCategory select {
  background: #fff;
  height: 55px;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  min-width: 250px;
  padding: 0 12px;
  border-radius: 6px;
}

.footerCategorySaveButton {
  background: var(--main);
  color: #fff;
  border-color: var(--main);
  font-size: 16px;
  margin: 0 auto;
  border: none;
  min-width: 510px;
  height: 48px;
  border-radius: 8px;
}
