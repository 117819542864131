#dropdown-basic {
    background-color: white;
    color: #6a7d8b;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    padding: 8px 8px 8px 8px;
    line-height: 25px;
}