.login_section .login_header .img_box img {
  width: 100%;
}
.login_section .login_header .login_title h1 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 25px;
}
.login_section .login_header .login_title p {
  margin: 0;
  font-size: 18px;
  line-height: 33px;
}

.login_section .login_header .logo_box a {
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 2px;
}
.login_section .form_box {
  padding: 20px 30px;
  width: 400px;
  background: #fff;
  margin: 10% auto;
  box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
  border-radius: 8px;
}
.login_section .form_box h2 {
  color: var(--main);
}
.login_section .form_box .form-control {
  background: #fff;
  height: 48px;
  border: 1px solid #e1e1e1;
  margin-bottom: 15px;
  font-size: 14px;
  box-shadow: none;
}
.login_section .form_box .form-control.btn {
  background: var(--main);
  color: #fff;
  border-color: var(--main);
  font-size: 16px;
}
.login_section .form_box .forgot {
  float: right;
  font-size: 14px;
  font-weight: 500;
}
.login_section .form_box .option_box {
  text-align: center;
  margin-top: 15px;
}
.login_section .form_box .option_box p {
  margin: 0;
}

.login_section .form_box h4 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  color: #373636;
  text-transform: uppercase;
  margin-top: 10px;
}

.login_section .form_box .logoImg {
  margin: 0 auto;
  margin-bottom: 20px;
}
